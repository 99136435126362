import React from 'react';
import logo from './assets/mainlogo.png';
import empty_logo from './assets/emptylogo.png';
import TextFlash from './Components/TextFlash';
import SignUp from './Components/SignUp';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderText: 0,
      renderSkip:0
    }
    this.delayText();
  }

  delayText() {
    const self = this;
    setTimeout(function() {
      self.setState({
        renderText:1
      })
    }, 25000);
    setTimeout(function() {
      self.setState({
        renderSkip:1
      })
    }, 3000);
  }

  skipText() {
    this.setState({
      renderText:1,
      renderSkip:0
    })
  }

  renderContainer() {
    if(this.state.renderText == 0) {
      return (
        <div className={"TextContainer"}>
          <TextFlash />
        </div>
      );
    } else {
      return (
        <div className={"SignUpContainer"}>
          <SignUp />
        </div>
      );
    }
  }

  renderSkip() {
    if(this.state.renderSkip == 1) {
      return (
        <div className={"SkipText"}
              onClick={this.skipText.bind(this)}>
          Skip
        </div>
      );
    }
  }

  render() {
    return (
      <div className="App">
        <div className={"AppLogo"}>
          <img src={empty_logo} className={"Logo"}/>
          <img src={logo} className={"LogoShadow"}/>
        </div>
        {this.renderContainer()}
        {this.renderSkip()}
      </div>
    );
  }
}

export default App;
